.tbl_modulestatus {
    width: 24px;
}

#cc_modules {
    max-width: 1100px;
}

.mdl_createmodule {
    width: 450px !important;
}

.mdl_createmodule .modal-content {
    padding: 24px;
}

.mdl_createmodule .module {
    width: 465px;
}