
.tbl_blogstatus {
    width: 24px;
}

#cc_blogs {
    max-width: 1300px
}

.mdl_createblog {
    width: 450px !important;
}

.mdl_createblog .modal-content {
    padding: 24px;
}

.mdl_createblog .blog {
    width: 465px;
}

#cc_authors {
    max-width: 1300px
}

.tbl_authorimage img {
    height: 20px;
    margin: 0;
    padding: 0;
}