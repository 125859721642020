
#module_details.content-topmenu {
    grid-template-columns: auto minmax(0, 1fr);
}

#cc_moduledetails {
    width: 900px;
}

#cc_moduleblocks {
    width: 900px;
}

#cc_moduleblocks .content-card-content {
    padding: 8px;
}

#cc_moduleblocks .content-card-content .tbl_order {
    width: 50px;
}

.btn_detailparent {
    padding-right: 8px;
    cursor: pointer;
}

.content-topmenu-detailname {
    color: #A9ADAC;
}

.mdl_addblock {
    width: 450px !important;
}

.mdl_addblock .modal-content {
    padding: 24px;
}

.mdl_addblock .block_order {
    width: 100px;
}

#cc_blockdetails .content-card-header {
    display: grid;
    grid-template-columns: auto 30px;
}

#cc_blockdetails .content-card-header-buttons {
    height: 22px;
    cursor: pointer;
}

#cc_blockdetails .content-card-header-buttons img {
    height: 100%;
}

#cc_moduleblocks .tbl_viewstatus {
    width: 100px;
}

#mdl_removeblockfrommodule {
    width: 450px;
}

#mdl_removeblockfrommodule .modal-header {
    color: #BE2020;
}

#mdl_removeblockfrommodule .modal-header img {
    height: 26px;
    margin: -10px 8px -7px -14px;
}

#mdl_removeblockfrommodule .modal-content {
    padding: 24px;
    line-height: 20px;
}

#mdl_removeblockfrommodule .activestate {
    background-color: #BE2020 !important;
    border-color: #BE2020 !important;
}

/*  MODAL: DELETE MODULE ---------------------------------------------------------------------------------------------*/

#mdl_deletemodule {
    width: 450px;
}

#mdl_deletemodule .modal-header {
    color: #BE2020;
}

#mdl_deletemodule .modal-header img {
    height: 26px;
    margin: -10px 8px -7px -14px;
}

#mdl_deletemodule .modal-content {
    padding: 24px;
    line-height: 20px;
}