
#cc_topics {
    max-width: 1100px;
}

.tbl_topicstatus {
    width: 24px;
    padding: 5px 2px 2px 4px !important;
}

.mdl_createtopic {
     width: 450px !important;
 }

.mdl_createtopic .modal-content {
    padding: 24px;
}

.mdl_createtopic .topic {
    width: 465px;
}