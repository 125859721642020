

.btn_detailparent {
    padding-right: 8px;
    cursor: pointer;
}

#learningpath_details.content-topmenu {
    grid-template-columns: auto minmax(0, 1fr);
}

.content-topmenu-detailname {
    color: #A9ADAC;
}

#cc_moduleblocks .content-card-content .tbl_order {
    width: 50px;
}

.mdl_addblock {
    width: 450px !important;
}

.mdl_addblock .modal-content {
    padding: 24px;
}

#mdl_removeblockfromlearningpath {
    width: 450px;
}

#mdl_removeblockfromlearningpath .modal-header {
    color: #BE2020;
}

#mdl_removeblockfromlearningpath .modal-header img {
    height: 26px;
    margin: -10px 8px -7px -14px;
}

#mdl_removeblockfromlearningpath .modal-content {
    padding: 24px;
    line-height: 20px;
}

#mdl_removeblockfromlearningpath .activestate {
    background-color: #BE2020 !important;
    border-color: #BE2020 !important;
}

/*  MODAL: DELETE LEARNING PATH --------------------------------------------------------------------------------------*/

#mdl_deletelearningpath {
    width: 450px;
}

#mdl_deletelearningpath .modal-header {
    color: #BE2020;
}

#mdl_deletelearningpath .modal-header img {
    height: 26px;
    margin: -10px 8px -7px -14px;
}

#mdl_deletelearningpath .modal-content {
    padding: 24px;
    line-height: 20px;
}