
.btn_detailparent {
    padding-right: 8px;
    cursor: pointer;
}

.content-topmenu-detailname {
    color: #A9ADAC;
}

#author_details.content-topmenu {
    grid-template-columns: auto minmax(0, 1fr);
}

#cc_authordetails {
    width: 900px;
}

#cc_authordetails .content-card-header {
    display: grid;
    grid-template-columns: auto 30px;
}

#cc_authordetails .content-card-header-buttons {
    height: 22px;
    cursor: pointer;
}

#cc_authordetails .content-card-header-buttons img {
    height: 100%;
}

#cc_authordetails .authorimage {
    padding-left: 8px;
    display: flex;
    cursor: pointer;
}

#cc_authordetails .authorimage img {
    height: 160px;
}

#cc_authordetails .author_firstname {
    width: 465px;
}

#cc_authordetails .author_lastname {
    width: 465px;
}

#cc_authordetails .author_role {
    width: 465px;
}

#cc_authordetails .author_bio {
    width: 465px;
}

.mdl_updateauthorimage {
    width: 450px !important;
}

.mdl_updateauthorimage .modal-content {
    padding: 24px;
}

.mdl_updateauthorimage_progressbar {
    margin-top: 24px !important;
}

.mdl_updateauthorimage #btn_filebrowse {
    width: 325px;
    margin-bottom: 16px;
}

