.tbl_learningpathstatus {
    width: 24px;
}


.mdl_createlearningpath {
    width: 450px !important;
}

.mdl_createlearningpath .modal-content {
    padding: 24px;
}

.mdl_createlearningpath .learningpath {
    width: 465px;
}