
.login_wrapper {
    display: grid;
    justify-items: center;
    align-items: center;
    height: 100vh;
    background-color: #2ea3a6;
}



.login {
    width: 300px;
    border: 1px solid #eaeaea;
    padding: 28px;
    border-radius: 5px;
    display: grid;
    grid-template-rows: 100px 1fr;
    background-color: #ffffff;
}

.login_logo {
    text-align: center;
}

.login_logo img{
    width: 90%;
}

#btn_login_submit {
    margin-top: 24px;
    float: right;
}