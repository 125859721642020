

.btn_detailparent {
    padding-right: 8px;
    cursor: pointer;
}

.content-topmenu-detailname {
    color: #A9ADAC;
}

#topic_details.content-topmenu {
    grid-template-columns: auto minmax(0, 1fr);
}

#cc_topicdetails {
    max-width: 700px;
}

.dd_pdfs {
    width: 621px;
}

.btn_viewdditem {
    padding-top: 8px;
    padding-left: 8px;
    cursor: pointer;
}

.btn_viewdditem img {
    height: 20px;
    margin: 0px 0px -2px 0px;
}

.btn_deletedditem {
    padding-top: 8px;
    padding-left: 8px;
    cursor: pointer;
}

.btn_deletedditem img {
    height: 20px;
    margin: 0px 0px -2px 0px;
}

.block {
    width: 465px;
}



/*  MODAL: REMOVE PDF FROM TOPIC -------------------------------------------------------------------------------------*/

#mdl_removeresourcefromtopic {
    width: 450px;
}

#mdl_removeresourcefromtopic .modal-header {
    color: #BE2020;
}

#mdl_removeresourcefromtopic .modal-header img {
    height: 26px;
    margin: -10px 8px -7px -14px;
}

#mdl_removeresourcefromtopic .modal-content {
    padding: 24px;
    line-height: 20px;
}

#mdl_removeresourcefromtopic .activestate {
    background-color: #BE2020 !important;
    border-color: #BE2020 !important;
}



/*  MODAL: REMOVE DOWNLOAD PDF FROM TOPIC ----------------------------------------------------------------------------*/

#mdl_removedownloadpdffromtopic {
    width: 450px;
}

#mdl_removedownloadpdffromtopic .modal-header {
    color: #BE2020;
}

#mdl_removedownloadpdffromtopic .modal-header img {
    height: 26px;
    margin: -10px 8px -7px -14px;
}

#mdl_removedownloadpdffromtopic .modal-content {
    padding: 24px;
    line-height: 20px;
}

#mdl_removedownloadpdffromtopic .activestate {
    background-color: #BE2020 !important;
    border-color: #BE2020 !important;
}



/*  MODAL: REMOVE MP4 FROM TOPIC -------------------------------------------------------------------------------------*/

#mdl_removemp4fromtopic {
    width: 450px;
}

#mdl_removemp4fromtopic .modal-header {
    color: #BE2020;
}

#mdl_removemp4fromtopic .modal-header img {
    height: 26px;
    margin: -10px 8px -7px -14px;
}

#mdl_removemp4fromtopic .modal-content {
    padding: 24px;
    line-height: 20px;
}

#mdl_removemp4fromtopic .activestate {
    background-color: #BE2020 !important;
    border-color: #BE2020 !important;
}



/*  MODAL: DELETE TOPIC ----------------------------------------------------------------------------------------------*/

#mdl_deletetopic {
    width: 450px;
}

#mdl_deletetopic .modal-header {
    color: #BE2020;
}

#mdl_deletetopic .modal-header img {
    height: 26px;
    margin: -10px 8px -7px -14px;
}

#mdl_deletetopic .modal-content {
    padding: 24px;
    line-height: 20px;
}