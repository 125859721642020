
#resource-details {
    display: flex;
}

.btn_detailparent {
    padding-right: 8px;
    cursor: pointer;
}

.content-topmenu-detailname {
    color: #A9ADAC;
}

#cc_resourcedetails {
    max-width: 700px;
}

.resource.content-card-content {
    padding: 24px 24px 48px 24px;
}

.resource .filename {
    width: 400px;
}

.resource .filetype {
    width: 70px;
}

.resource .topic {
    width: 465px;
}

#cc_resourcedetails .btn_viewdditem {
    padding-top: 8px;
    padding-left: 8px;
    cursor: pointer;
}

#cc_resourcedetails .btn_viewdditem img {
    height: 20px;
    margin: 24px 0px -2px 0px;
}

#cc_resourcedetails .btn_deletedditem {
    padding-top: 8px;
    padding-left: 8px;
    cursor: pointer;
}


#cc_resourcedetails .btn_deletedditem img {
    height: 20px;
    margin: 24px 0px -2px 0px;
}

#mdl_removefromtopic {
    width: 450px;
}

#mdl_removefromtopic .modal-header {
    color: #BE2020;
}

#mdl_removefromtopic .modal-header img {
    height: 26px;
    margin: -10px 8px -7px -14px;
}

#mdl_removefromtopic .modal-content {
    padding: 24px;
    line-height: 20px;
}

/*  MODAL: DELETE RESOURCE -------------------------------------------------------------------------------------------*/

#mdl_deleteresource {
    width: 450px;
}

#mdl_deleteresource .modal-header {
    color: #BE2020;
}

#mdl_deleteresource .modal-header img {
    height: 26px;
    margin: -10px 8px -7px -14px;
}

#mdl_deleteresource .modal-content {
    padding: 24px;
    line-height: 20px;
}