


.tbl_resourcestatus {
    width: 38px;
    padding: 5px 2px 2px 4px !important;
}

th.tbl_resourcestatus {
    padding-top: 10px !important;
}

.mdl_createresource {
    width: 450px !important;
}

.mdl_createresource .modal-content {
    padding: 24px;
}

.mdl_createresource_progressbar {
    margin-top: 24px !important;
}

.mdl_createresource #btn_filebrowse {
    width: 325px;
    margin-bottom: 16px;
}