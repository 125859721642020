
.tbl_blockstatus {
    width: 24px;
}

.tbl_blockimage {
    padding: 5px 0px 0px 0px !important;
}

.tbl_blockimage img {
    height: 20px;
    margin: 0;
    padding: 0;
}

.mdl_createblock {
    width: 450px !important;
}


.mdl_createblock .modal-content {
    padding: 24px;
}

.mdl_createblock .block {
    width: 465px;
}