

.btn_detailparent {
    padding-right: 8px;
    cursor: pointer;
}

#landingpage_details.content-topmenu {
    grid-template-columns: auto minmax(0, 1fr);
}

.content-topmenu-detailname {
    color: #A9ADAC;
}

.landingpage {
    width: 465px;
}

.mdl_addmodule {
    width: 450px !important;
}

.mdl_addmodule .modal-content {
    padding: 24px;
}

.dd_modules {
    width: 400px;
}

#mdl_removemodulefromlandingpage {
    width: 450px;
}

#mdl_removemodulefromlandingpage .modal-header {
    color: #BE2020;
}

#mdl_removemodulefromlandingpage .modal-header img {
    height: 26px;
    margin: -10px 8px -7px -14px;
}

#mdl_removemodulefromlandingpage .modal-content {
    padding: 24px;
    line-height: 20px;
}

#mdl_removemodulefromlandingpage .activestate {
    background-color: #BE2020 !important;
    border-color: #BE2020 !important;
}