

.btn_detailparent {
    padding-right: 8px;
    cursor: pointer;
}

.content-topmenu-detailname {
    color: #A9ADAC;
}

#block_details.content-topmenu {
    grid-template-columns: auto minmax(0, 1fr);
}

#cc_blockdetails {
    width: 900px;
}

#cc_blockdetails .content-card-header {
    display: grid;
    grid-template-columns: auto 30px;
}

#cc_blockdetails .content-card-header-buttons {
    height: 22px;
    cursor: pointer;
}

#cc_blockdetails .content-card-header-buttons img {
    height: 100%;
}

#cc_blockdetails .blockimage {
    padding-left: 8px;
    display: flex;
    cursor: pointer;
}

#cc_blockdetails .blockimage img {
    height: 160px;
}

.mdl_updateblockimage {
    width: 450px !important;
}

.mdl_updateblockimage .modal-content {
    padding: 24px;
}

.mdl_updateblockimage_progressbar {
    margin-top: 24px !important;
}

.mdl_updateblockimage #btn_filebrowse {
    width: 325px;
    margin-bottom: 16px;
}

#cc_blocktopics {
    width: 900px;
}

#cc_blocktopics .content-card-content {
    padding: 8px;
}

#cc_blocktopics .content-card-content .tbl_order {
    width: 50px;
}

.tbl_topicstatus {
    width: 24px;
}

.tbl_topicview {
    width: 24px;
}

#cc_blockmoduleassignment {
    width: 700px;
}

#cc_blockmoduleassignment .content-card-content {
    padding: 8px;
}

.module {
    width: 465px;
}

#cc_blocklearningpathassignment {
    width: 700px;
}

#cc_blocklearningpathassignment  .content-card-content {
    padding: 8px;
}

.learningpath {
    width: 465px;
}

.demo-editor {
    padding: 0px 16px;
}

.demo-wrapper {
    border: 1px solid #BBBFBE;
    border-radius: 4px;
}

.demo-toolbar {
    border-radius: 0;
    border: unset !important;
    border-bottom: 1px solid #BBBFBE !important;
}

.mdl_addtopic .modal-content {
    padding: 24px;
}

.mdl_addtopic {
    width: 550px !important;
}

.mdl_addtopic .topic_order {
    width: 100px;
}

#mdl_removetopicfromblock {
    width: 450px;
}

#mdl_removetopicfromblock .modal-header {
    color: #BE2020;
}

#mdl_removetopicfromblock .modal-header img {
    height: 26px;
    margin: -10px 8px -7px -14px;
}

#mdl_removetopicfromblock .modal-content {
    padding: 24px;
    line-height: 20px;
}

#mdl_removetopicfromblock .activestate {
    background-color: #BE2020 !important;
    border-color: #BE2020 !important;
}

/*  MODAL: DELETE BLOCK ----------------------------------------------------------------------------------------------*/

#mdl_deleteblock {
    width: 450px;
}

#mdl_deleteblock .modal-header {
    color: #BE2020;
}

#mdl_deleteblock .modal-header img {
    height: 26px;
    margin: -10px 8px -7px -14px;
}

#mdl_deleteblock .modal-content {
    padding: 24px;
    line-height: 20px;
}