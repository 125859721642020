
.mdl_createdemoaccount {
    width: 450px !important;
}


.mdl_createdemoaccount .modal-content {
    padding: 24px;
}

.mdl_createdemoaccount .input {
    width: 100%;
}

.passwordvalidation {
    color: #363c5b;
    font-weight: 300;
    font-size: 13px;
    line-height: 16px;
}

.passwordvalidation_item {
    display: flex;
    padding-bottom: 2px;
}

.passwordvalidation_item_icon {
    width: 16px;
    margin-right: 6px;
    margin-top: -3px;
}

.passwordvalidation_item_icon img {
    width: 100%;
    height: 100%;
}

.inactivestate {
    opacity: .5;
}

.passwordvalidation_message {
    margin-top: 24px;
}

.mdl_updatedemoaccount {
    width: 450px !important;
}


.mdl_updatedemoaccount .modal-content {
    padding: 24px;
}

.mdl_updatedemoaccount .input {
    width: 100%;
}