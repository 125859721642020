
.app-container {
  display: grid;
  grid-template-rows: 60px auto;
  height: 100vh;
}
.app-topmenu {
  display: grid;
  grid-template-columns: 200px auto 250px;
  border-bottom: 1px solid #E4E8E7;
}

.app-topmenu-logo {
  padding: 9px 0px 0px 16px;
}

.app-topmenu-logo img {
  height: 40px;
}

.app-topmenu-menu {
  display: flex;
  align-items: center;
  padding-left: 24px;
}

.app-topmenu-menuitem {
  font-size: 15px;
  font-weight: 400;
  color: #848A89;
  padding-right: 24px;
  cursor: pointer;
}

.app-topmenu-loggedin-username {
  font-size: 15px;
  font-weight: 600;
  color: #4B4F4E;
  padding-top: 16px;
  line-height: 11px;
}

.app-topmenu-loggedin-logout {
  font-size: 13px;
  font-weight: 400;
  color: #2ea3a6;
  cursor: pointer;
}

.app-topmenu-loggedin-logout:hover {
  color: #237d80;
}

.app-content {
  display: grid;
  grid-template-columns: 200px auto;
}

.app-sidenav {
  border-top: 2px solid #F8F9F9;
  border-right: 1px solid #E4E8E7;
  padding: 16px 0px 0px 16px;
}

.app-sidenav-header {
  font-size: 12px;
  font-weight: 700;
  color: #C6CECC;
  padding-bottom: 16px;
}

.app-sidenav-item {
  font-size: 14px;
  font-weight: 400;
  color: #676C6B;
  padding-bottom: 12px;
  cursor: pointer;
}

.app-sidenav-item:hover {
  color: #2ea3a6;
}

.content {
  display: grid;
  grid-template-rows: 60px auto;

  height: 100%;
}

.content-topmenu {
  background-color: #F8F9F9;
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 4px;
  border-bottom: 1px solid #E4E8E7;
  border-top: 2px solid #F4F6F5;
  font-size: 17px;
  font-weight: 600;
  color: #4B4F4E;
}

.content-topmenu .ui.button {
  background-color: #2ea3a6;
  border: 1px solid #2ea3a6;
  font-size: 11px;
  font-weight: 700;
  color: #ffffff;
  padding: 10px 16px 10px 16px;
  width: fit-content;
  display: flex;
  justify-self: flex-end;
}

.content-wrapper {
  background-color: #eef1f0;
  padding: 24px;
}

.content-card {
  width: 100%;
  display: grid;
  grid-template-rows: 60px auto;
  background-color: #fff;
  border: 1px solid #e4e8e7;
  border-radius: 4px;
  margin-bottom: 24px;
}

.content-card-header {
  font-size: 19px;
  font-weight: 700;
  color: #4b4f4e;
  border-bottom: 1px solid #e4e8e7;
  letter-spacing: -.5px;
  display: flex;
  align-items: center;
  padding: 0 24px;
}

.content-card-content {
  padding: 8px;
}

.content-card-footer {
  border-top: 1px solid #E8E9E9;
  height: 56px;
  background-color: #F8F9F9;
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
}

.content-card-footer-lastmodified {
  font-weight: 100;
  font-size: 12px;
  font-style: italic;
  color: #969C9B;
  padding-left: 16px;
}

.content-card-footer-buttons {
  display: flex;
  justify-content: flex-end;
  padding-right: 8px;
}

.content-card-footer-buttons .ui.button {
  background-color: #ffffff;
  border: 1px solid #E8E9E9;
  font-size: 11px;
  font-weight: 600;
  color: #676C6B;
  padding: 10px 16px 10px 16px;
}

.btn_viewitem {
  padding-top: 28px;
  padding-left: 8px;
  cursor: pointer;
}

.btn_viewitem img {
  height: 20px;
  margin: 0px 0px -2px 0px;
}

.modal-header {
  font-size: 19px;
  font-weight: 700;
  color: #4b4f4e;
  border-bottom: 1px solid #e4e8e7;
  letter-spacing: -.5px;
  display: flex;
  align-items: center;
  padding: 16px 24px 12px 24px;
}

.modal-content {
  padding: 8px;
}

.modal-footer {
  border-top: 1px solid #E8E9E9;
  height: 56px;
  background-color: #F8F9F9;
  display: grid;
  align-items: center;

}

.modal-footer-buttons {
  display: flex;
  justify-content: flex-end;
  padding-right: 8px;
}


.modal-footer-buttons .ui.button {
  background-color: #ffffff;
  border: 1px solid #E8E9E9;
  font-size: 11px;
  font-weight: 600;
  color: #676C6B;
  padding: 10px 16px 10px 16px;
}


/*  semantic ui components -------------------------------------------------------------------------------------------*/

input,
textarea {
  font-family: "Open Sans", sans-serif !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  padding-bottom: 8px !important;
  padding-top: 8px !important;
}

.ui.action.input>.button {
  background-color: #ffffff !important;
  border: 1px solid #BBBFBE !important;
  font-size: 11px;
  font-weight: 600;
  color: #676C6B;
}

.ui.button {
  font-family: "Open Sans", sans-serif !important;
}

.ui.primary.button,
.ui.primary.buttons .button,
.ui.loading.loading.loading.loading.loading.loading.button {
  font-family: "Open Sans", sans-serif !important;
  background-color: #2ea3a6;
  color: #fff;
  text-shadow: none;
  background-image: none;
}

.ui.primary.button:hover,
.ui.primary.buttons .button:hover {
  background-color: #2ea3a6;
}

.ui.button.activestate {
  background-color: #2ea3a6;
  border-color: #2ea3a6;
  color: #ffffff;
  font-weight: 700;
}

.ui.table {
  border: none;
  font-size: 13px;
  font-weight: 400;
  color: #676C6B;
}

.ui.compact.table td {
  padding: .5em .7em .3em .7em;
}

.ui.table thead th {
  background-color: #ffffff;
  font-size: 11px;
  font-weight: 600;
  color: #C6CECC;
  padding-top: 16px;
  padding-bottom: 8px;
}

.ui.table tr {
  cursor: pointer;
}

.table_statusicon {
  height: 17px;
  margin: 0px 0px -2px 0px;
}

.ui.form .field>label {
  font-size: 11px !important;
  font-weight: 600 !important;
  color: #494C4B !important;
  margin: 0px 0px 4px 0px !important;
}

.ui.input>input,
.ui.form  .field.field input {
  font-size: 13px !important;
  font-weight: 400 !important;
  color: #676C6B !important;
  border-color: #BBBFBE !important;
  background-color: #ffffff !important;
}

.ui.form textarea {
  font-size: 13px !important;
  font-weight: 400 !important;
  color: #676C6B !important;
  border-color: #BBBFBE !important;
  background-color: #ffffff !important;
}

.ui.form .fields>.field {
  padding-right: 0px !important;
}

.ui.radio.checkbox {
  padding-right: 5px !important;
}

.ui.label {
  font-size: 11px !important;
  font-weight: 600 !important;
  color: #494C4B !important;
  margin: 0 !important;
  background-color: #ffffff;
  display: block;
  padding-left: 0px !important;
}

.ui.radio.checkbox label {
  padding-left: 20px !important;
  font-size: 13px !important;
  font-weight: 400 !important;
  color: #676C6B !important;
}

.ui.selection.dropdown {
  margin-left: 7px !important;
  height: 33px;
  min-height: unset;
  padding: 8px 30px 8px 13px;
  border-color: #BBBFBE !important;
  background-color: #ffffff !important;
}

.ui.selection.dropdown>.dropdown.icon {
  top: unset;
}

.ui.selection.active.dropdown .menu {
  border-color: #BBBFBE !important;
}

.ui.selection.dropdown {
  font-size: 13px !important;
  font-weight: 400 !important;
  color: #676C6B !important;
}

.ui.dropdown .menu>.item {
  font-size: 13px !important;
  font-weight: 400 !important;
  color: #676C6B !important;
}

.ui.dropdown .menu .selected.item {
  font-weight: 600 !important;
}

.ui.progress {
  background: #ffffff !important;
  border: solid 1px #BBBFBE !important;
  border-radius: 4px !important;
}

.ui.progress .bar {
  background: #3DBF1C !important;
  border-radius: 3px !important;
}